import { useConnection } from '@/hooks/use-connection';
import { AnimatePresence } from 'framer-motion';
import { lazy } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PrivateRoutes } from './private/private-routes';

const Dashboard = lazy(() => import('@/pages/dashboard'));
const Draw = lazy(() => import('@/pages/draw'));
const DrawList = lazy(() => import('@/pages/draw-list'));
const DrawQuestions = lazy(() => import('@/pages/draw-questions'));
const DrawText = lazy(() => import('@/pages/draw-text'));
const CreateDraw = lazy(() => import('@/pages/draw/create-draw'));
const CreateUserParticipationType = lazy(() => import('@/pages/draw/create-draw/create-user-participation'));
const DrawAttendees = lazy(() => import('@/pages/draw/draw-attendees'));
const DrawManagement = lazy(() => import('@/pages/draw/draw-management'));
const DrawResult = lazy(() => import('@/pages/draw/draw-result'));
const ReleaseDraw = lazy(() => import('@/pages/draw/release-draw'));
const Login = lazy(() => import('@/pages/login'));
const CreateUser = lazy(() => import('@/pages/public-pages/create-user'));
const MyDraws = lazy(() => import('@/pages/public-pages/my-draws'));
const TryMyLuck = lazy(() => import('@/pages/public-pages/try-my-luck'));
const Users = lazy(() => import('@/pages/users'));
const LostPage = lazy(() => import('@/templates/lost'));
const NotAccessConsolePage = lazy(() => import('@/templates/not-access-console'));
const WithoutConnection = lazy(() => import('@/templates/without-connection'));

export default function Rotas() {
	const { isConnected } = useConnection();

	return (
		<AnimatePresence mode="wait" presenceAffectsLayout onExitComplete={() => null}>
			<Router key={location.pathname}>
				{!isConnected ? (
					<WithoutConnection />
				) : (
					<Routes>
						<Route path="/" element={<Login />} />

						<Route element={<PrivateRoutes />}>
							<Route path="/dashboard" element={<Dashboard />} />

							<Route path="/new-draw" element={<CreateDraw />} />
							<Route path="/new-draw/:id" element={<CreateDraw />} />
							<Route path="/new-draw/participation-type" element={<CreateUserParticipationType />} />
							<Route path="/new-draw/participation-type/:id" element={<CreateUserParticipationType />} />

							<Route path="/draw/:id" element={<Draw />} />

							<Route path="/draw/questions/:id" element={<DrawQuestions />} />
							<Route path="/draw/text/:id" element={<DrawText />} />
							<Route path="/draw/list/:id" element={<DrawList />} />

							<Route path="/draw/release/:id" element={<ReleaseDraw />} />
							<Route path="/draw/result/:id" element={<DrawResult />} />
							<Route path="/draw/attendees/:id" element={<DrawAttendees />} />

							<Route path="/draw-management" element={<DrawManagement />} />

							<Route path="/users" element={<Users />} />
						</Route>

						<Route path="/draw/try-my-luck/:id" element={<TryMyLuck />} />
						<Route path="/create-user" element={<CreateUser />} />
						<Route path="/my-draws/:id/:cpf" element={<MyDraws />} />

						<Route path="/nao-permitimos-acesso-ao-console" element={<NotAccessConsolePage />} />
						<Route path="*" element={<LostPage />} />
					</Routes>
				)}
			</Router>
		</AnimatePresence>
	);
}
